<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <label class="label">Titulo de la entrada</label>
      <div class="control">
        <input
          required
          class="input"
          type="text"
          v-model="localPost.name"
          name="title"
          v-validate="'required'"
          data-vv-as="titulo"
          :class="{'is-danger': vverrors.has('title')}"
        >
        <p class="help is-danger"> {{vverrors.first('title')}} </p>
      </div>
    </div>
    <div class="column is-6">
      <div class="columns is-multiline">
        <div class="column is-12">
          <label class="label">Autor</label>
          <div class="control">
            <input
              required
              class="input"
              type="text"
              v-model="localPost.author"
              name="author"
              v-validate="'required'"
              data-vv-as="Autor"
              :class="{'is-danger': vverrors.has('title')}"
            >
              <p class="help is-danger"> {{vverrors.first('title')}} </p>
          </div>
        </div>
        <div class="column is-12">
          <label class="label">Categoría</label>
          <v-select
            :multiple="true"
            v-model="categoriesSelected"
            :options="categoriesList"
            name="categoryId"
            v-validate="'required'"
            data-vv-as="categoría"
          />
          <p class="help is-danger"> {{vverrors.first('categoryId')}} </p>
        </div>
        <div class="column is-12">
          <label class="label">Descripción Corta</label>
          <div class="control">
            <textarea
              class="textarea"
              v-model="localPost.short_description"
              name="shortDescription"
              v-validate="'required|max:170'"
              data-vv-as="descripción corta"
              maxlength="170"
            />
          </div>
          <p class="help is-danger"> {{vverrors.first('shortDescription')}} </p>
        </div>
      </div>
    </div>
    <div class="column is-6">
      <div class="columns is-multiline">
        <div class="column is-12">
          <div class="control">
            <div class="file has-name is-boxed flex_center">
              <label class="file-label full-width">
                <input
                  required
                  class="file-input"
                  type="file"
                  name="image"
                  @change="uploadFile"
                  data-vv-as="imagen"
                  accept=".jpeg,.jpg,.png"
                >
                <span class="file-cta">
                <template v-if="!previewImage && !localPost.image">
                  <span class="file-label">
                    Agregar imagen
                  </span>
                  </template>
                  <div class="content__image">
                    <img :src="previewImage || `${ASSETS_URL}${localPost.image}`" class="image__uploading control_image">
                  </div>
                </span>
                <label class="has-text-centered" :class="{'file-name file-name-text': !fileName}" v-if="previewImage">
                  {{fileName || '----'}}
                </label>
              </label>
            </div>
            <p class="help is-danger"> {{vverrors.first('image')}} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-12">
      <label class="label">Contenido</label>
      <div class="control">
        <vue-editor
          ref="editor"
          v-model="localPost.long_description"
          name="longDescription"
          v-validate="'required'"
          data-vv-as="contenido"
        />
      </div>
      <p class="help is-danger"> {{vverrors.first('longDescription')}} </p>
    </div>
    <div class="column">
      <button
        class="button is-danger"
        @click="close"
        :class="{ 'is-loading': loading }"
        :disabled="loading"
      >
        Cancelar
      </button>
    </div>
    <div class="column">
      <button
        class="button save-button is-success is-pulled-right"
        @click="edtiPostDetail"
        :class="{ 'is-loading': loading }"
        :disabled="loading"
      >
        Guardar
      </button>
    </div>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions } from 'vuex'
import vselect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
  components: {
    VueEditor,
    'v-select': vselect
  },
  data () {
    return {
      ASSETS_URL: process.env.VUE_APP_ASSETS_URL,
      localPost: {},
      title: null,
      content: null,
      author: null,
      image: {},
      fileName: null,
      cover: {},
      categoryId: null,
      categories: [],
      loading: false,
      previewImage: null,
      categoriesList: [],
      categoriesSelected: []
    }
  },
  props: {
    postData: {
      type: Object
    },
    postId: {
      type: Number
    },
    editPost: {
      type: Function
    },
    close: {
      type: Function
    }
  },
  beforeMount () {
    this.localPost = this.postData
    this.categoriesSelected = this.postData.categories.reduce((accum, element) => {
      accum.push({
        id: element.id,
        label: element.name
      })
      return accum
    }, [])
    this.getCatalogCategories()
      .then(categoriesList => {
        this.categories = categoriesList
        this.categoriesList = categoriesList.reduce((accum, element) => {
          accum.push({
            id: element.id,
            label: element.name
          })
          return accum
        }, [])
      })
  },
  methods: {
    ...mapActions(['getCatalogCategories', 'addTemporalNotification']),
    uploadFile (event) {
      let uploadFile = event.target.files[0]
      if (uploadFile.size > 1100000) {
        this.addTemporalNotification({ type: 'danger', message: 'El peso de la imagen no puede exceder 1 MB' })
      } else {
        const [ file ] = event.target.files
        this[event.target.name] = { key: event.target.name, file }
        this.fileName = file.name
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = e => {
          this.previewImage = e.target.result
        }
      }
    },
    async edtiPostDetail () {
      this.loading = true
      if (await this.$validator.validateAll()) {
        const data = {
          title: this.localPost.name,
          shortDescription: this.localPost.short_description,
          longDescription: this.localPost.long_description,
          author: this.localPost.author,
          image: this.image,
          categoryId: this.localPost.category_id,
          categories: this.categoriesSelected
        }
        await this.editPost({ postId: this.postId, data })
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
  textarea {
    resize: none;
  }
  .control_image {
    display: block;
    margin: auto;
    max-height: 400px;
  }
  .flex_center {
    display: flex;
    justify-content: center;
  }
</style>
